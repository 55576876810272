<template>
  <div>
    <hero-bar>
      Usuários
      <!-- <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link> -->
      <b-button slot="right"
        label="Cadastrar usuário"
        @click="isComponentModalActive = true"
      />
    </hero-bar>

    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Usuários"
        icon="account-multiple"
      >
        <b-table
          :per-page="10"
          :striped="true"
          :hoverable="true"
          :data="usersList"
          :loading="isLoading"
        >
          <b-table-column label="Nome" field="nome" v-slot="props">
            {{ props.row.Name }}
          </b-table-column>
          <b-table-column label="Tipo" field="Tipo" v-slot="props">
            {{ props.row.Tipo }}
          </b-table-column>
          <b-table-column label="DTCreated" field="DTCreated" v-slot="props">
            {{ formatDate(props.row.DTCreated) }}
          </b-table-column>
          <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
            <div class="buttons is-right">
              <b-button @click="openModalToEditUser(props.row)" class="button is-small is-primary">
                <b-icon icon="account-edit" size="is-small"/>
              </b-button>
              <b-button @click="confirmDelete({ id: props.row.IDUser, name: props.row.Name })" class="button is-small is-danger">
                <b-icon icon="delete" size="is-small"/>
              </b-button>
            </div>
          </b-table-column>

        </b-table>
      </card-component>
    </section>

    <b-modal
        v-model="isComponentModalActive"
        has-modal-card
        @close="reset"
    >
          <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{isEditMode ? 'Edição' : 'Cadastro'}} de usuário</p>
                </header>
                <section class="modal-card-body">
                  <form @submit.prevent="submit">
                    <b-field label="Usuário" horizontal>
                      <b-field>
                        <b-input
                          v-model="form.name"
                          icon="account"
                          placeholder="Name"
                          name="name"
                          required
                          :disabled="isEditMode"
                        />
                      </b-field>
                    </b-field>
                    <b-field label="Tipo de usuário" horizontal>
                      <b-select
                        v-model="form.type"
                        placeholder="Selecione o tipo do usuário"
                        required
                      >
                        <option
                          v-for="(type, index) in userTypes"
                          :key="index"
                          :value="type"
                        >
                          {{ type }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Senha" horizontal>
                      <b-input
                        v-model="form.password"
                        placeholder="Senha do usuário"
                        required
                      />
                    </b-field>
                  </form>
                </section>
                <footer class="modal-card-foot">
                   <b-button type="is-primary is-outlined" @click="reset">Reset</b-button>
                    <b-button
                      v-if="isEditMode"
                      label="Editar"
                      type="is-primary"
                      @click="editUser"
                      :loading="isLoadingForm"
                    />
                    <b-button
                      v-else
                      label="Cadastrar"
                      type="is-primary"
                      @click="submit"
                      :loading="isLoadingForm"
                    />
                </footer>
            </div>
    </b-modal>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import {
  getUsers,
  insertUser,
  deleteUser,
  editUser,
  notificationError,
  formatDate,
  resetFields,
  reponseHandler
} from '@/core'

export default {
  name: 'Forms',
  components: {
    HeroBar,
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      isLoadingForm: false,
      usersList: [],
      isComponentModalActive: false,
      isEditMode: false,
      form: {
        name: null,
        type: null,
        password: null
      },
      userTypes: ['Administrador', 'Comum'],
      listExample: [
        {
          IDUser: '1',
          Name: 'filipe',
          Tipo: 'Comum',
          DTCreated: '2022-02-18 00:00:00'
        },
        {
          IDUser: '5',
          Name: 'teste3',
          Tipo: 'Administrador',
          DTCreated: '2022-02-24 01:55:49'
        }
      ]
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Forms']
    }
  },
  methods: {
    getUsers () {
      this.isLoading = true
      getUsers()
        .then(res => {
          this.isLoading = false
          this.usersList = res
        })
        .catch(() => {
          this.isLoading = false
          notificationError(this.$buefy.notification)
        })
    },
    submit () {
      this.isLoadingForm = true
      const user = this.form
      insertUser(user.name, user.password, user.type)
        .then(res => {
          reponseHandler(res, () => {
            this.isLoadingForm = false
            this.isComponentModalActive = false
            this.reset()
            this.getUsers()
          }, () => {
            this.isLoadingForm = false
            this.isComponentModalActive = false
          })
        })
        .catch(() => {
          this.isLoadingForm = false
          notificationError(this.$buefy.notification)
        })
    },
    reset () {
      this.form = resetFields(this.form)
      this.isEditMode = false
    },
    formatDate: (v) => formatDate(v),
    confirmDelete (user) {
      this.$buefy.dialog.confirm({
        title: 'Deletar usuário',
        message: `Tem certeza que deseja deletar o usuário ${user.Name}? Essa ação não pode ser desfeita.`,
        confirmText: 'Deletar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteUser(user)
      })
    },
    deleteUser (user) {
      deleteUser(user.id)
        .then(res => reponseHandler(res, () => this.getUsers()))
        .catch(() => notificationError(this.$buefy.notification))
    },
    openModalToEditUser (user) {
      this.isEditMode = true
      this.form = {
        id: user.IDUser,
        name: user.Name,
        type: user.Tipo,
        password: null
      }

      this.isComponentModalActive = true
    },
    editUser () {
      const user = this.form
      editUser(user.id, user.password, user.type)
        .then(res => {
          reponseHandler(res, () => {
            this.isComponentModalActive = false
            this.reset()
            this.getUsers()
          }, () => {
            this.isComponentModalActive = false
          })
        })
        .catch(() => {
          this.isComponentModalActive = false
          notificationError(this.$buefy.notification)
        })
    }
  },
  created () {
    this.getUsers()
  }
}
</script>
